import {addScriptTag} from '@lib/client/dom';

/**
 * Installs the vidyard script tag.
 */
export function setupVidyardVideoComponent() {
  setTimeout(() => {
    addScriptTag({
      src: 'https://play.vidyard.com/embed/v4.js',
      async: true,
    });
  }, 50);
}
